export const GET_SCHOOL = 'GET_SCHOOL';

export const userConstants = {
    LOGIN_REQUEST: 'USERS_LOGIN_REQUEST',
    LOGIN_SUCCESS: 'USERS_LOGIN_SUCCESS',
    LOGIN_FAILURE: 'USERS_LOGIN_FAILURE',

    LOGOUT: 'USERS_LOGOUT',
};
  
export const loginViaModeConstants = {
   FETCH_REQUEST: 'SCHOOL_FETCH_REQUEST',
   FETCH_SUCCESS: 'SCHOOL_FETCH_SUCCESS',
   FETCH_FAILURE: 'SCHOOL_FETCH_FAILURE',

   REMOVE: 'REMOVE_USER'

}
export const dataConstants = {
   FETCH_PENDING: 'DATA_FETCH_PENDING',
   FETCH_SUCCESS: 'DATA_FETCH_SUCCESS',
   FETCH_FAILURE: 'DATA_FETCH_FAILURE',

};
export const schoolDataConstants = {
   FETCH_PENDING: 'DATA_FETCH_PENDING',
   FETCH_SUCCESS: 'DATA_FETCH_SUCCESS',
   FETCH_FAILURE: 'DATA_FETCH_FAILURE',

};


export const schoolInfoConstants = {
   UPDATE_PENDING: 'UPDATE_PENDING',
   UPDATE_SUCCESS: 'UPDATE_SUCCESS',
   UPDATE_FAILURE: 'UPDATE_FAILURE',


   FETCH_PENDING: 'INFO_FETCH_PENDING',
   FETCH_SUCCESS: 'INFO_FETCH_SUCCESS',
   FETCH_FAILURE: 'INFO_FETCH_FAILURE',

 F_FETCH_PENDING: 'INFO_FETCH_PENDING',
  F_FETCH_SUCCESS: 'INFO_FETCH_SUCCESS',
  F_FETCH_FAILURE: 'INFO_FETCH_FAILURE',

   IMAGE_FETCH_PENDING: 'IMAGE_FETCH_PENDING',
   IMAGE_FETCH_SUCCESS: 'IMAGE_FETCH_SUCCESS',
   IMAGE_FETCH_FAILURE: 'IMAGE_FETCH_FAILURE',

   IMAGE_UPLOAD_PENDING: 'IMAGE_UPLOAD_PENDING',
   IMAGE_UPLOAD_SUCCESS: 'IMAGE_UPLOAD_SUCCESS',
   IMAGE_UPLOAD_FAILURE: 'IMAGE_UPLOAD_FAILURE',

   UPDATE_IMAGE_HOVER_IN: 'UPDATE_IMAGE_HOVER_IN',
   UPDATE_IMAGE_HOVER_OUT: 'UPDATE_IMAGE_HOVER_OUT'

};
