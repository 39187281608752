export const SCHOOLS = [
  {
    src: 'http://localhost:8088/superset/dashboard/dungri/?standalone=true',
    schoolName: 'Govt. Girl sec. school sheetala dungri',
    state: 'Rajasthan',
    district: 'Jaipur',
    schoolCode: 'dungri',
    id:5
  },
  {
    src: 'http://localhost:8088/superset/dashboard/Jayatpura/?standalone=true',
    schoolName: 'Govt. Adarsh Sr. Sec. School, RadhaSwami ka Bag, Jayatpura',
    state: 'Rajasthan',
    district: 'Jaipur',
    schoolCode: 'jayatpura',
    id: 6
  },
  {
    src: 'http://localhost:8088/superset/dashboard/jalsu/?standalone=true',
    schoolName: 'Govt. Adarsh Sr. Sec. School, Khorashymdas,Jalsu',
    state: 'Rajasthan',
    district: 'Jaipur',
    schoolCode: 'jalsu',
    id:4

  }
];
